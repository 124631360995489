import wbsQuery from '../../queries/wbs';
import { DEFAULT_REPORT_TAKE_VALUE } from '../../constants';
import packageQuery from '../../queries/packages';
import estimateQueries from '../../queries/estimate';
import resourcesQuery from '../../queries/resources';
import unitrateQuery from '../../queries/unitrates';
import scheduleQuery from '../../queries/schedule';
import projectInfoQuery from '../../queries/projectInfo';
import { GET_CURRENCY } from '../../queries/projectCurrency';
import projectPhaseQuery from '../../queries/phase';
import porjectCaseQuery from '../../queries/case';
import labourQuery from '../../queries/labourAndCrew';
import coaQuery from '../../queries/coa';
import projectQueryVersion from '../../queries/version';
import unitCostQueries from '../../queries/unitCost';

interface FieldType {
    Name: string,
    DataField: string,
}

interface ReportQueryDetails {
    query: string,
    jsPath: string,
    operationName?: string,
    fields: FieldType[],
    module?: string,
    endpoint?: string,
    exchangeRate?: number,
    transformData?: (p: any)=> void
}

const getAllInfoOfProject = () => {
    const datafieldArr = [
        // 'id',
        'client_number',
        'consultant',
        'owner',
        'name',
        'estimate_type',
        'estimate_accuracy',
        'base_date',
        // 'hide_values',
        // 'consultant_number',
        'contingency_wbs',
        'contingency_pct',
        // 'currency',
        // 'project_number',
        // 'version_id',
        // 'version_code',
        // 'industry_id',
        // 'subindustry_id',
    ].map((item) => ({ Name: item, DataField: item }));

    datafieldArr.push({ Name: 'currency_code', DataField: 'currency_data.code' });
    datafieldArr.push({ Name: 'version', DataField: 'version_code' });
    datafieldArr.push({ Name: 'last modified', DataField: 'updated_at' });
    datafieldArr.push({ Name: 'industry', DataField: 'industry.description' });
    datafieldArr.push({ Name: 'subindustry', DataField: 'subindustry.description' });
    // datafieldArr.push({ Name: 'currency_id', DataField: 'currency_data.id' });
    // datafieldArr.push({ Name: 'currency_exchange_rate', DataField: 'currency_data.exchange_rate' });

    datafieldArr.push(
        ...[
            'country_name',
            'region',
            'location',
            'latitude',
            'longitude',
            'wbs1',
            'wbs2',
            'wbs3',
            'wbs4',
            'wbs5',
            'wbs6',
            'coa1',
            'coa2',
            'coa3',
            'coa4',
            'coa5',
            'coa6',
            'wbs_sep',
            'coa_sep',
        ].map((item) => ({ Name: item, DataField: item })),
    );

    return datafieldArr;
};

const getEstimateData = () => {
    const arr = [];
    arr.push({ Name: 'wbs_code', DataField: 'wbs.code' });
    arr.push({ Name: 'wbs_description', DataField: 'wbs.description' });
    arr.push({ Name: 'coa_code', DataField: 'coa.code' });
    arr.push({ Name: 'coa_description', DataField: 'coa.description' });
    arr.push({ Name: 'case_code', DataField: 'case.code' });
    arr.push({ Name: 'case_description', DataField: 'case.name' });
    arr.push({ Name: 'phase_code', DataField: 'phase.code' });
    arr.push({ Name: 'phase_description', DataField: 'phase.name' });
    arr.push({ Name: 'item', DataField: 'sequence' });
    arr.push({ Name: 'class', DataField: 'class' });
    // arr.push({ Name: 'wbs_id', DataField: 'wbs_id' });
    // arr.push({ Name: 'coa_id', DataField: 'coa_id' });
    // arr.push({ Name: 'case_id', DataField: 'case_id' });
    // arr.push({ Name: 'phase_id', DataField: 'phase_id' });
    // arr.push({ Name: 'Lab Pkg', DataField: 'package_labour_id' });
    arr.push({ Name: 'package_labour_code', DataField: 'package_labour.code' });
    // arr.push({ Name: 'Ceq Pkg', DataField: 'package_construction_equip_id' });
    arr.push({ Name: 'package_construction_code', DataField: 'package_construction.code' });
    // arr.push({ Name: 'Mat Pkg', DataField: 'package_material_id' });
    arr.push({ Name: 'package_material_code', DataField: 'package_material.code' });
    // arr.push({ Name: 'Eqp Pkg', DataField: 'package_permanent_equip_id' });
    arr.push({ Name: 'package_permanent_code', DataField: 'package_permanent.code' });
    // arr.push({ Name: 'Sub Pkg', DataField: 'package_subcontract_id' });
    arr.push({ Name: 'package_subcontract_code', DataField: 'package_subcontract.code' });
    // arr.push({ Name: 'Ind Pkg', DataField: 'package_indirect_id' });
    arr.push({ Name: 'package_indirect_code', DataField: 'package_indirect.code' });
    // arr.push({ Name: 'Unit Rate', DataField: 'unit_rate_id' });
    arr.push({ Name: 'unit_rate_code', DataField: 'unit_rate.code' });
    arr.push({ Name: 'item_type', DataField: 'item_type' });
    arr.push({ Name: 'description', DataField: 'Description' });
    arr.push({ Name: 'description_2', DataField: 'detailed_description' });
    arr.push({ Name: 'base_quantity', DataField: 'base_quantity' });
    arr.push({ Name: 'design_growth', DataField: 'design_growth' });
    arr.push({ Name: 'quantity', DataField: 'Quantity' });
    arr.push({ Name: 'unit', DataField: 'unit_of_measure' });
    arr.push({ Name: 'prod_factor', DataField: 'prod_factor' });
    arr.push({ Name: 'wastage_factor', DataField: 'wastage_factor' });
    arr.push({ Name: 'markup_labour', DataField: 'markup_labour' });
    arr.push({ Name: 'markup_construction_equip', DataField: 'markup_construction_equip' });
    arr.push({ Name: 'markup_material', DataField: 'markup_material' });
    arr.push({ Name: 'markup_permanent_equip', DataField: 'markup_permanent_equip' });
    arr.push({ Name: 'markup_subcontract', DataField: 'markup_subcontract' });
    arr.push({ Name: 'unit_man_hours', DataField: 'unit_man_hours' });
    arr.push({ Name: 'unit_labour_cost', DataField: 'unit_labour_cost' });
    arr.push({ Name: 'unit_consequip_cost', DataField: 'unit_consequip_cost' });
    arr.push({ Name: 'unit_material_cost', DataField: 'unit_material_cost' });
    arr.push({ Name: 'unit_Pequipment_cost', DataField: 'unit_Pequipment_cost' });
    arr.push({ Name: 'unit_subcontract_cost', DataField: 'unit_subcontract_cost' });
    arr.push({ Name: 'unit_indirect_cost', DataField: 'unit_indirect_cost' });
    arr.push({ Name: 'labour_hours', DataField: 'labour_hours' });
    arr.push({ Name: 'labour_cost', DataField: 'labour_cost' });
    arr.push({ Name: 'construction_equip_cost', DataField: 'construction_equip_cost' });
    arr.push({ Name: 'material_cost', DataField: 'material_cost' });
    arr.push({ Name: 'permanent_equip_cost', DataField: 'permanent_equip_cost' });
    arr.push({ Name: 'subcontract_cost', DataField: 'subcontract_cost' });
    arr.push({ Name: 'indirect_cost', DataField: 'indirect_cost' });
    arr.push({ Name: 'total_cost', DataField: 'total_cost' });
    arr.push({ Name: 'Total_Unit_Cost', DataField: 'Total_Unit_Cost' });
    arr.push({ Name: 'ur_code', DataField: 'ur_code' });
    arr.push({ Name: 'wbs_self_desc', DataField: 'wbs.self_code_desc' });
    arr.push({ Name: 'gCoa Self Desc', DataField: 'gCoa.description' });
    arr.push({ Name: 'wbs_first_parent', DataField: 'wbs.first_parent' });
    arr.push({ Name: 'wbs_second_parent', DataField: 'wbs.second_parent' });
    arr.push({ Name: 'wbs_third_parent', DataField: 'wbs.third_parent' });
    arr.push({ Name: 'wbs_forth_parent', DataField: 'wbs.forth_parent' });
    arr.push({ Name: 'wbs_fifth_parent', DataField: 'wbs.fifth_parent' });
    arr.push({ Name: 'coa_self_desc', DataField: 'coa.self_code_desc' });
    arr.push({ Name: 'coa_first_parent', DataField: 'coa.first_parent' });
    arr.push({ Name: 'coa_second_parent', DataField: 'coa.second_parent' });
    arr.push({ Name: 'coa_third_parent', DataField: 'coa.third_parent' });
    arr.push({ Name: 'coa_forth_parent', DataField: 'coa.forth_parent' });
    arr.push({ Name: 'coa_fifth_parent', DataField: 'coa.fifth_parent' });
    // arr.push({ Name: 'wbs_code1', DataField: 'wbs.code1' });
    // arr.push({ Name: 'wbs_code2', DataField: 'wbs.code2' });
    // arr.push({ Name: 'wbs_code3', DataField: 'wbs.code3' });
    // arr.push({ Name: 'wbs_code4', DataField: 'wbs.code4' });

    return arr;
};

const getUnitRateData = () => {
    const dataArr = [
        // 'id',
        'coa_code',
        // 'generic_coa_id',
        // 'productivity',
        'code',
        'description',
        'unit',
        'productivity',
        'unit_man_hours',
        'lab_rate',
        'unit_labour_cost',
        'unit_material_cost',
        'unit_consequip_cost',
        'unit_equipment_cost',
        'unit_subcontract_cost',
        'unit_indirect_cost',
        // 'created_at',
        // 'updated_at',
        // 'quantity',
        // 'quantity',
        // 'total_cost',
        // 'total_unit_cost',
        // 'total_quantity',
        // 'unit_rate',
    ].map((item) => ({ Name: item, DataField: item }));

    dataArr.push({ Name: 'unit_rate', DataField: 'unit_cost' });
    dataArr.push({ Name: 'quantity', DataField: 'quantity_in_estimate' });
    dataArr.push({ Name: 'total_cost', DataField: 'cost_in_estimate' });
    dataArr.push({ Name: 'total_unit_cost', DataField: 'total_unit_cost' });
    dataArr.push({ Name: 'quantity_child_rates', DataField: 'quantity_child_rates' });

    return dataArr;
};

const getUnitRateDetailData = () => {
    const dataArr = [];
    dataArr.push({ Name: 'unit_rate', DataField: 'code' });
    dataArr.push({ Name: 'code', DataField: 'entity_code' });
    dataArr.push({ Name: 'description', DataField: 'entity_description' });
    dataArr.push({ Name: 'quantity', DataField: 'quantity' });
    dataArr.push({ Name: 'total_quantity', DataField: 'total_quantity' });
    dataArr.push({ Name: 'unit', DataField: 'entity_unit' });
    dataArr.push({ Name: 'entity_cost', DataField: 'entity_cost' });
    dataArr.push({ Name: 'labour_hours', DataField: 'labour_hours' });
    dataArr.push({ Name: 'labour', DataField: 'labour' });
    dataArr.push({ Name: 'material', DataField: 'material' });
    dataArr.push({ Name: 'equipment', DataField: 'equipment' });
    dataArr.push({ Name: 'p_equipment', DataField: 'p_equipment' });
    dataArr.push({ Name: 'subcontract', DataField: 'subcontract' });
    dataArr.push({ Name: 'total_unit_resource', DataField: 'total_unit_resource' });

    return dataArr;
};

const getEstimatesUnitCostData = () => {
    const fieldsArr = getUnitRateDetailData().map((field) => {
        const dataField = field.DataField;
        return {
            ...field,
            DataField: `unit_cost_data.${dataField}`,
        };
    });

    const arr = getEstimateData().concat(fieldsArr);

    const repeated = ['description', 'unit', 'labour_hours', 'quantity'];
    const colsArr = arr.filter((col) => {
        if (col.Name === 'ur_code' || col.Name === 'unit_rate') {
            return false;
        }
        if (repeated.includes(col.Name)) {
            // eslint-disable-next-line no-param-reassign
            col.Name = col.DataField.includes('unit_cost_data.') ? `unit_cost_${col.Name}` : `estimate_${col.Name}`;
        }
        return true;
    });

    return colsArr;
};

const getResourcesData = () => {
    const dataArr = [
        // 'id',
        // 'type',
        // 'code',
        // 'generic_code',
        'code',
        'description',
        // 'unit',
        // 'updated_at',
    ].map((item) => ({ Name: item, DataField: item }));

    dataArr.push({ Name: 'Unit', DataField: 'unit' });

    dataArr.push(
        ...[
            'cost_input',
            'currency',
            // 'unit',
            'date',
            'escalation_factor',
            'cost_per_unit',
            'unit_rate',
            'total_quantity',
            'total_cost',
            'type',
        ].map((item) => ({ Name: item, DataField: item })),
    );

    return dataArr;
};

const getScheduleData = () => {
    const dataArr = [];

    // dataArr.push({ Name: 'id', DataField: 'id' });
    dataArr.push({ Name: 'code', DataField: 'code' });
    dataArr.push({ Name: 'Description', DataField: 'name' });
    dataArr.push({ Name: 'start_date', DataField: 'start_date' });
    dataArr.push({ Name: 'end_date', DataField: 'end_date' });
    dataArr.push({ Name: 'labour_hours', DataField: 'labour_hours' });
    dataArr.push({ Name: 'labour', DataField: 'labour' });
    dataArr.push({ Name: 'c_equipment', DataField: 'c_equipment' });
    dataArr.push({ Name: 'material', DataField: 'material' });
    dataArr.push({ Name: 'subcontract', DataField: 'subcontract' });
    dataArr.push({ Name: 'equipment', DataField: 'equipment' });
    dataArr.push({ Name: 'distributable', DataField: 'distributable' });
    dataArr.push({ Name: 'total_cost', DataField: 'total_cost' });
    // dataArr.push({ Name: 'created_at', DataField: 'created_at' });
    // dataArr.push({ Name: 'updated_at', DataField: 'updated_at' });
    return dataArr;
};

const getAllModuleQueryDetails = (orgId: string, projectId: string, versionId: string, exchangeRate: number) => {
    const queryDetails = [] as ReportQueryDetails[];
    queryDetails.push({
        endpoint: '/setting',
        module: 'Project_Info',
        query: projectInfoQuery.GET_PROJECTIFO_BY_ID(
            projectId,
            orgId,
        ),
        jsPath: '.data.project_setting',
        fields: getAllInfoOfProject(),
    });
    // Wbs data
    queryDetails.push({
        module: 'Wbs',
        query: wbsQuery.GET_WBS_INFO(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            false,
        ),
        jsPath: '.data.wbs.data.*',
        fields: [
            // { Name: 'id', DataField: 'id' },
            // { Name: 'generic_wbs_id', DataField: 'generic_wbs_id' },
            // { Name: 'required', DataField: 'required' },
            { Name: 'code1', DataField: 'code1' },
            { Name: 'code2', DataField: 'code2' },
            { Name: 'code3', DataField: 'code3' },
            { Name: 'code4', DataField: 'code4' },
            { Name: 'code5', DataField: 'code5' },
            { Name: 'code6', DataField: 'code6' },
            { Name: 'code', DataField: 'code' },
            { Name: 'description', DataField: 'description' },
            // { Name: 'detailed_description', DataField: 'detailed_description' },
            // { Name: 'class', DataField: 'class' },
            // { Name: 'created_at', DataField: 'created_at' },
            // { Name: 'updated_at', DataField: 'updated_at' },
            { Name: 'labour_hours', DataField: 'labour_hours' },
            { Name: 'labour', DataField: 'labour' },
            { Name: 'cons_equipment', DataField: 'cons_equipment' },
            { Name: 'material', DataField: 'material' },
            { Name: 'subcontract', DataField: 'subcontract' },
            { Name: 'equipment', DataField: 'equipment' },
            { Name: 'distributables', DataField: 'distributables' },
            { Name: 'total_cost', DataField: 'total_cost' },
            // { Name: 'level', DataField: 'level' },
        ],
    });
    // Coa data
    queryDetails.push({
        module: 'Coa',
        query: coaQuery.GET_COA_INFO(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            '',
            '',
            orgId,
            projectId,
            versionId,
            false,
        ),
        jsPath: '.data.coa.data.*',
        fields: [
            // 'id',
            // 'required',
            'code1',
            'code2',
            'code3',
            'code4',
            'code5',
            'code6',
            'code',
            'description',
            // 'detailed_description',
            // 'class',
            // 'prod_factor',
            // 'created_at',
            // 'updated_at',
            'labour_hours',
            'labour',
            'cons_equipment',
            'material',
            'subcontract',
            'equipment',
            'indirect',
            'total_cost',
            // 'level',
        ].map((item) => ({ Name: item, DataField: item })),
    });
    // Project case data
    queryDetails.push({
        endpoint: '/setting',
        module: 'Project_Case',
        query: porjectCaseQuery.GET_PROJECT_CASE(
            orgId,
            projectId,
            versionId,
        ),
        jsPath: '.data.case.*',
        fields: [
            'code',
            'name',
            // 'base',
            'total_cost'].map((item) => ({ Name: item, DataField: item })),
    });
    // Project phase data
    queryDetails.push({
        endpoint: '/setting',
        module: 'Project_Phase',
        query: projectPhaseQuery.GET_PROJECT_PHASE(
            orgId,
            projectId,
            versionId,
        ),
        jsPath: '.data.phase.*',
        fields: [
            'code',
            'name',
            'total_cost'].map((item) => ({ Name: item, DataField: item })),
    });
    // Estimates data
    queryDetails.push({
        module: 'Estimate',
        query: estimateQueries.GET_ESTIMATE_DETAILS(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            '',
            '',
            '',
            '',
            '',
            '',
            '[]',
            '[]',
            exchangeRate,
        ),
        jsPath: '.data.estimate_detail.data.estimatedData.*',
        fields: getEstimateData(),

    });
    // Unit Cost Estimates
    queryDetails.push({
        module: 'Unit_Cost_Estimates',
        query: unitCostQueries.GET_UNIT_COST_ESTIMATES(
            0,
            10000,
            orgId,
            projectId,
            versionId,
            exchangeRate,
        ),
        jsPath: '.data.unit_cost_estimate.data.estimatedData.*',
        fields: getEstimatesUnitCostData(),
    });
    // Recursive unit cost estimates
    queryDetails.push({
        module: 'Unit_Cost_Estimates_Resources',
        query: unitCostQueries.GET_UNIT_COST_ESTIMATES(
            0,
            10000,
            orgId,
            projectId,
            versionId,
            exchangeRate,
            true,
        ),
        jsPath: '.data.unit_cost_estimate.data.estimatedData.*',
        fields: getEstimatesUnitCostData(),
    });
    // Unit Rate data
    queryDetails.push({
        module: 'Unit_Rate',
        query: unitrateQuery.GET_UNIT_RATE_LIST_VIEW_DATA(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            '',
            false,
            '',
            exchangeRate,
            '',
            [],
            [],
            '',
            '',
            '',
        ),
        jsPath: '.data.unitrateListView.data.*',
        fields: getUnitRateData(),
    });
    // Unit Rate Detail data
    queryDetails.push({
        module: 'Unit_Cost',
        query: unitrateQuery.GET_UNIT_COST_REPORT_DATA(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            '',
            '',
            orgId,
            projectId,
            versionId,
            false,
        ),
        jsPath: '.data.unitrateReport.data[*]',
        fields: getUnitRateDetailData(),
    });
    // Resources data
    queryDetails.push({
        module: 'Resources',
        query: resourcesQuery.GET_RESOURCES_INFO(
            '',
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            '',
            false,
            false,
        ),
        jsPath: '.data.resource.data.*',
        fields: getResourcesData(),
    });
    // Project currency data
    queryDetails.push({
        endpoint: '/setting',
        module: 'Project_Currency',
        query: GET_CURRENCY(
            orgId,
            projectId,
        ),
        jsPath: '.data.getprojectCurrency.*',
        fields: [
            // 'id',
            'code',
            'name',
            'exchange_rate',
            'total_cost'].map((item) => ({ Name: item, DataField: item })),
    });
    // Packages data
    queryDetails.push({
        module: 'Packages',
        query: packageQuery.GET_PACKAGES(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            false,
        ),
        jsPath: '.data.package.data.packageData.*',
        fields: [
            // 'id',
            'code',
            'name',
            'labour_hours',
            'labour',
            'cons_equipment',
            'material',
            'subcontract',
            'equipment',
            'distributables',
            'total_cost',
            // 'created_at',
            // 'updated_at',
        ].map((item) => ({ Name: item, DataField: item })),
    });
    // Schedule data
    queryDetails.push({
        module: 'Schedule',
        query: scheduleQuery.GET_SCHEDULE_BY_ORGID(
            0,
            DEFAULT_REPORT_TAKE_VALUE,
            orgId,
            projectId,
            versionId,
            false,
        ),
        jsPath: '.data.schedule.data.scheduleData.*',
        fields: getScheduleData(),
    });
    // Labour and crew rates data
    queryDetails.push({
        module: 'Labour_&_Crew_Rates',
        query: labourQuery.GET_LABOUR_AND_CREW_INFO(
            orgId,
            projectId,
            versionId,
        ),
        jsPath: '.data.labour_crew_rates.data.*',
        fields: [
            // 'code1',
            // 'code2',
            // 'code3',
            // 'code4',
            // 'code5',
            // 'code6',
            'code',
            // 'level',
            'account_description',
            'labour_hours',
            'labour_rate',
            'const_distribution',
            'sub_total',
            'const_equip',
            'total',
            'prod_factor',
        ].map((item) => ({ Name: item, DataField: item })),
    });
    // Project version data
    queryDetails.push({
        endpoint: '/setting',
        module: 'Project_Version',
        query: projectQueryVersion.GET_PROJECT_VERSION(
            orgId,
            projectId,
        ),
        jsPath: '.data.version.*',
        fields: [
            // 'id',
            'code',
            'name',
            'date',
            'updated_by',
            // 'isCurrentVersion',
            // 'org_id',
            // 'project_id',
            // 'total_cost',
            // 'created_at',
            // 'updated_at',
        ].map((item) => ({ Name: item, DataField: item })),
    });
    return queryDetails;
};

export { getAllModuleQueryDetails };
