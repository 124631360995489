import { makeStyles } from '@mui/styles';
import { createBreakpoints } from '@mui/system';

const breakpoints = createBreakpoints({});

const DonutChartStyle = makeStyles(() => ({
    chartSec: {
        width: '100%',
        '& .recharts-wrapper': {
            '& .recharts-legend-wrapper': {
                width: '50% !important',
                height: 'inherit !important',
                overflowY: 'auto',
                [breakpoints.down(1350)]: {
                    width: '40% !important',
                },
                '& .recharts-default-legend': {
                    '& .recharts-legend-item': {
                        // display: 'flex !important',
                        '& svg': {
                            position: 'unset !important',
                            left: 'unset !important',
                        },
                        '& .recharts-surface': {
                            height: '6px',
                            marginTop: '4px',
                        },
                        '& .recharts-legend-item-text': {
                            fontSize: '12px',
                        },
                    },
                },
            },
        },
        '& .recharts-layer': {
            outline: 'none !important',
        },
    },
    headerBarchartStyle: {
        width: '40px',
        '& .recharts-pie': {
            cursor: 'pointer !important',
        },
        '& .recharts-tooltip-wrapper': {
            zIndex: '999 !important',
            top: '15px !important',
        },
    },
    tooltip: {
        width: '110px',
        backgroundColor: '#fff',
        padding: '5px 10px',
        border: '1px solid #ccc',
    },
}));

export default DonutChartStyle;
