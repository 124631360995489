import React, { useContext } from 'react';
import CostHeaderBarStyle from './costHeaderBar.style';
import { Box, Tooltip } from '@mui/material';
import { useFormatCurrency } from '../../hooks/useFormatCurrency';
import { ProjectContext } from '../../store/context/projectContext';
import DonutChart from '../DonutChart/DonutChartHeader';

interface DonutData {
    label: string,
    value: number,
}
export default function CostHeaderBar() {
    const classes = CostHeaderBarStyle();
    const customCellRender = useFormatCurrency();
    const projectCtx = useContext(ProjectContext);

    const colors = ['#35B83D', '#484DFF'];

    const orgCost = projectCtx?.orgTotalCost;

    const data: DonutData[] = [
        {
            label: 'Direct',
            value: (orgCost?.direct) ? Number(orgCost.direct) : 0,
        },
        {
            label: 'Indirect',
            value: orgCost?.indirect ? Number(orgCost.indirect) : 0,
        },
    ];

    return (
        <Box className={classes.chartStyle}>
            <span className={classes.costSec} style={{ color: colors[0] }}>
                <span>Direct</span>
                <Tooltip title={customCellRender((orgCost?.direct) || '')}>
                    <span className={classes.value}>{customCellRender((orgCost?.direct) || '')}</span>
                </Tooltip>
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className={classes.costSec} style={{ color: colors[1] }}>
                Indirect
                <Tooltip title={customCellRender((orgCost?.indirect) || '')}>
                    <span className={classes.value}>{customCellRender((orgCost?.indirect) || '')}</span>
                </Tooltip>
            </span>
            &nbsp;&nbsp;&nbsp;
            <span className={classes.costSec} style={{ color: '#353535' }}>
                Total
                <Tooltip title={customCellRender((orgCost?.total) || '')}>
                    <span className={classes.value}>{customCellRender((orgCost?.total) || '')}</span>
                </Tooltip>
            </span>
            &nbsp;&nbsp;
            <DonutChart chartData={data} outerRd={11} height={25} />
        </Box>
    );
}
